
              @import "@/assets/css/variables/variables.scss";
            
.purple_button {
  height: 46px;
  border-radius: $button-radius;
  border: 0px;
  background-color: $primary-purple;
  color: $white;
  line-height: 23px;

  &:focus {
    background-color: $primary-purple;
    color: #fff;
  }

  &:hover,
  &:active {
    background-color: $hover-blue;
    color: $white;
  }
}

.white_button {
  height: 46px;
  border-radius: $button-radius;
  border: solid 1px $primary-purple;
  background-color: unset;
  color: $primary-purple;
  margin-right: 10px;
  line-height: 23px;

  &:focus {
    border: solid 1px $primary-purple;
    background-color: unset;
    color: $primary-purple;
  }

  &:hover,
  &:active {
    background-color: $hover-blue;
    color: $white;
  }
}

.reset_button {
  height: 46px;
  border-radius: $button-radius;
  border: solid 1px $primary-purple;
  background-color: $hover-blue;
  color: $primary-purple;
  margin-right: 10px;
  line-height: 23px;

  &:focus {
    border: solid 1px $primary-purple;
    background-color: unset;
    color: $primary-purple;
  }

  &:hover,
  &:active {
    background-color: $hover-blue;
    color: $white;
  }
}

.table_item_box {
  margin-bottom: 50px;

  .title {
    font-size: 32px;
    font-weight: 500;
    line-height: 1.38;
    color: $primary-purple;
    margin-bottom: 29px;
  }
}

.white {
  color: #c4cbd4;
}

.gray {
  color: #617286;
}

.red {
  color: #e91545;
}

.green {
  color: #41ba92;
}

.blue {
  color: $primary-purple;
}

/* 分页开始 */
.page_box {
  margin-bottom: 50px;
  @include rtl-sass-value(text-align, right, left);
}

.page_box .el-pagination_box {
  @include rtl-sass-value(float, right, left);
}

/* 分页结束 */

@media (max-width: 768px) {
  .page_box {
    text-align: center;
  }

  .page_box .el-pagination_box {
    float: none;
    display: block;
  }
}

@media (max-width: 550px) {
  .page_box .el-pagination_box {
    overflow-x: scroll;
    padding-bottom: 15px;
  }

  .page_box .el-pagination_box /deep/ .el-pagination {
    width: 495px;
  }

  .purple_button,
  .white_button,
  .reset_button {
    margin-bottom: 10px;
  }

  .table_item_box {
    .title {
      font-size: 24px;
    }
  }
}