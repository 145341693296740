
              @import "@/assets/css/variables/variables.scss";
            
.el-main {
  padding: 0 !important;
}

/* 修改滚动条样式开始 */

/* 修改滚动条样式开始 */

/*定义滚动条宽高  背景色*/
.scroll_box::-webkit-scrollbar,
.el-pagination_box::-webkit-scrollbar,
.el-table__body-wrapper::-webkit-scrollbar {
  width: 13px;
  height: 13px;
}

/*定义滚动条轨道 内阴影+圆角*/
.scroll_box::-webkit-scrollbar-track,
.el-pagination_box::-webkit-scrollbar-track,
.el-table__body-wrapper::-webkit-scrollbar-track {
  border-radius: 8px;
  background: #f5f5f5;
}

/*定义滑块 内阴影+圆角*/
.scroll_box::-webkit-scrollbar-thumb,
.el-pagination_box::-webkit-scrollbar-thumb,
.el-table__body-wrapper::-webkit-scrollbar-thumb {
  cursor: pointer;
  border-radius: 8px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: $primary-purple;
}

/* 滑块hover */
.scroll_box::-webkit-scrollbar-thumb:hover,
.el-pagination_box::-webkit-scrollbar-thumb:hover,
.el-table__body-wrapper::-webkit-scrollbar-thumb:hover {
  border-radius: 8px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

/* 修改滚动条样式结束 */

/* table 表格样式开始 */

.el-table,
.el-table__expanded-cell {
  background-color: transparent;
  box-shadow: $box-shadow;
  border: 3px solid $primary-purple;
  border-radius: $container-radius;
  border-width: 0 3px 3px 3px;
}

.el-table thead {
  background: $primary-purple;
}

/* tr和th的背景色 */
.el-table th,
.el-table tr {
  padding: 0;
  background: transparent !important;
}

.el-table td,
.el-table th {
  text-align: center;
}

.el-table tr {
  box-shadow: 0px 24px 3px -24px #b1b1b1;
}

.el-table td {
  border-bottom: unset;
}

.el-table th {
  color: $white;
}

.el-table td {
  padding: 0;
}

.el-table .el-table__cell {
  padding: 0px;
}

.el-table th .cell {
  padding: 25px 20px;
  word-break: break-word;
  text-align: center;
}

.el-table td .cell {
  padding: 25px 12px;
  word-break: break-word;
  color: $primary-purple;
  text-align: center;
}

.el-table__empty-text {
  color: $primary-purple;
}

/* table 表格样式结束 */

/* 分页开始 */
.el-pagination {
  font-size: 16px;
  font-weight: 200;
  line-height: 1.56;
}

/* 按钮默认背景色 */
.el-pagination button,
.el-pagination.is-background .el-pager li {
  height: 40px;
  line-height: 40px;
  background-color: unset !important;
}

.el-pagination.is-background .el-pager li.active {
  color: $primary-purple !important;
  border-bottom: solid 3px $primary-purple;
}

/* 分页结束 */

/* 遮罩层开始 */
.el-popup-parent--hidden {
  padding-right: 0 !important;
}

.el-dialog__wrapper {
  padding: 0 20px;

  .el-dialog {
    width: 100%;
    max-width: 673px;
    margin: 0 auto;
    box-shadow: 0 9px 35px 0 rgba(0, 0, 0, 0.13);
    top: 50%;
    transform: translateY(-50%);
  }

  .el-dialog__header {
    @include rtl-sass-value(text-align, right, left);

    .closeImg {
      cursor: pointer;
    }

    // hide default close icon
    .el-dialog__headerbtn {
      display: none;
    }
  }

  .el-dialog__body {
    padding: 0 48px 40px;
  }

  .el-dialog__footer {
    padding: 30px;
  }
}

/* 遮罩层结束 */

/* button 按钮开始 */
/* button 按钮结束 */

/* input 框开始 */
.el-input__inner {
  height: auto;
  line-height: normal;
  padding: 13px 10px;
  border: 0px;
  border-bottom: 1px solid $primary-purple;
  background: none;
  color: $primary-purple;
  border-radius: 0px;
  font-size: 20px;

  &:focus {
    border-color: $primary-purple !important;
  }
}

.el-input__inner::-webkit-input-placeholder {
  /* WebKit, Blink, Edge */
  color: #999999;
}

.el-input__inner:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: #999999;
}

.el-input__inner::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: #999999;
}

.el-input__inner:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #999999;
}

.el-input__validateIcon {
  color: #67c23a;
}

/* input 框结束 */

/* upload 开始 */
.el-upload {
  width: 100%;
  border: 3px dashed $primary-green;
  border-radius: $container-radius;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  padding: 30px 0;

  &:hover {
    border-color: $primary-green;
  }

  &:focus {
    border-color: $primary-green;
  }
}

.vUpload {
  display: grid;
  grid-template-columns: auto 1fr;
  column-gap: 30px;
}

.upload_demo .button_upload {
  height: 40px;
  font-size: 12px;
  line-height: 40px;
  padding: 0 15px;
  color: #ffffff;
  position: relative;
  @include rtl-sass-prop(left, right, 0);
  top: 0;
  border-radius: 23px;
  background-color: $primary-purple;
}

.el-upload__tip {
  font-size: 10px;
  font-weight: 200;
  line-height: 1.4;
  color: $primary-purple;
  margin-top: 0;
  @include rtl-sass-value(text-align, left, right);
}

.el-upload-list__item-name {
  @include rtl-sass-prop(margin-right, margin-left, 40px);
  @include rtl-sass-prop(margin-left, margin-right, unset);
  @include rtl-sass-prop(padding-left, padding-right, 4px);
  @include rtl-sass-prop(padding-right, padding-left, unset);
}

.el-upload-list__item-status-label {
  @include rtl-sass-prop(right, left, 5px);
  @include rtl-sass-prop(left, right, unset);
}

/* upload 结束 */

/* form 表单开始 */
.el-form-item {
  position: relative;
  margin-bottom: 40px;
}

.el-form-item__label {
  position: absolute;
  @include rtl-sass-prop(left, right, 0);
  bottom: 13px;
  transition: 0.2s;
  font-size: 16px;
  color: $primary-purple;
  @include rtl-sass-value(text-align, left, right);
  line-height: unset;
}

.el-form-item.is-required:not(.is-no-asterisk) .el-form-item__label-wrap>.el-form-item__label:before,
.el-form-item.is-required:not(.is-no-asterisk)>.el-form-item__label:before {
  @include rtl-sass-prop(margin-right, margin-left, 4px);
}

.el-form-item.is-error .el-form-item__label,
.el-form-item.is-error .el-checkbox__label {
  color: #e91d2c !important;
}

.el-form-item.is-error .el-input__inner {
  border-bottom: 1px solid #e91d2c !important;
}

.el-form-item__error {
  color: #e91d2c;
  font-size: 14px;
  @include rtl-sass-prop-sd(right, 0);
}

.el-form-item.is-error .el-form-item__content .el-checkbox__label {
  color: #e91d2c;
}

.el-form-item.is-error .el-form-item__content .el-checkbox__inner {
  border: 1px solid #e91d2c;
}

.el-input__suffix {
  @include rtl-sass-prop(right, left, 0px);
  @include rtl-sass-prop(left, right, unset);
}

.el-input--suffix .el-input__inner {
  @include rtl-sass-prop-sd(padding-right, 0);
}

.el-input.is-disabled .el-input__inner {
  background-color: unset;
  border: unset;
  border-bottom: 1px solid $primary-purple;
  color: $primary-purple;
}

.labelTitle {
  line-height: 14px;

  .required_icon {
    color: #e91d2c;
    @include rtl-sass-prop(margin-right, margin-left, 4px);
  }

  label {
    color: $primary-green;
  }
}

.is-error .labelTitle label {
  color: #e91d2c !important;
}

/* form 表单结束 */

/* select 开始 */
.el-select {
  width: 100%;
}

.el-select .el-input__icon {
  display: none !important;
}

.el-select .el-input__inner {
  border: 0;
  color: $primary-purple;
  border-bottom: 1px solid $primary-purple;
  background-size: 24px 24px !important;
  @include rtl-sass-value(background,
    url(~@/assets/images/icon_caret_bottom.png) no-repeat right center transparent,
    url(~@/assets/images/icon_caret_bottom.png) no-repeat left center transparent);

  &::placeholder {
    opacity: 0;
  }

  &:hover {
    border-bottom: 1px solid $primary-purple;
  }

  &:focus {
    border-bottom: 1px solid $primary-purple !important;
  }
}

.el-select .is-focus .el-input__inner {
  background-size: 24px 24px !important;
  @include rtl-sass-value(background,
    url(~@/assets/images/icons-navigation-cheveron-up@3x.png) no-repeat right center transparent,
    url(~@/assets/images/icons-navigation-cheveron-up@3x.png) no-repeat left center transparent);
}

.showPlaceholder {

  .el-select .el-input__inner,
  .el-input .el-input__inner {
    &::placeholder {
      opacity: 1;
    }
  }

  .el-form-item__label {
    font-size: 14px;
    bottom: 45px;
  }
}

.el-dropdown-menu,
.el-select-dropdown {
  border: 0 !important;
  padding: 0 !important;
  box-shadow: 0 9px 35px 0 rgba(0, 0, 0, 0.13);
  background-color: #ffffff;

  /* 小箭头 */
  .popper__arrow {
    display: none;
  }
}

.el-dropdown-menu__item:not(.is-disabled):hover,
.el-select-dropdown__item.selected,
.el-select-dropdown__item.hover,
.el-select-dropdown__item:hover {
  background-color: #ffffff;
  color: $primary-purple;
}

.el-dropdown-menu__item:hover span,
.el-dropdown-menu__item.active span {
  color: $primary-purple !important;
}

/* select 结束 */

.el-message {
  width: 80%;
  min-width: 80%;
  background-color: #ffffff;
  border: unset;
  box-shadow: 0 9px 35px 0 rgba(0, 0, 0, 0.13);
  padding: 32px 24px;
  font-size: 16px;
  font-weight: 200;
  line-height: 1.56;
  color: $primary-purple;

  .el-icon-warning:before,
  .el-icon-info:before,
  .el-icon-error:before {
    content: url(~@/assets/images/icons-error.png);
  }
}

/* messageBox 开始 */
.el-message-box {
  .el-button {
    color: #666666;
  }

  .el-button--primary {
    color: #fff;
  }
}

/* messageBox 结束 */

/* checkbox 开始 */
.el-checkbox {
  white-space: pre-wrap;
  width: 100%;

  .el-checkbox__input {
    .el-checkbox__inner {
      &:hover {
        border-color: #cccfd4;
      }
    }

    &.is-checked {
      .el-checkbox__inner {
        background-color: #ffffff;
        border-color: #cccfd4;

        &::after {
          border-color: $primary-purple;
        }
      }

      +.el-checkbox__label {
        color: $primary-purple;
      }
    }

    &.is-focus {
      .el-checkbox__inner {
        border-color: #cccfd4;
      }
    }
  }

  .el-checkbox__label {
    width: calc(100% - 36px);
    @include rtl-sass-prop(padding-left, padding-right, 18px);
    color: $primary-purple;
  }
}

/* checkbox 结束 */

/* Radio 开始 */
.el-radio__inner:hover {
  border-color: $primary-purple;
}

.el-radio__input.is-checked .el-radio__inner {
  border-color: $primary-purple;
  background-color: $primary-purple;
}

/* Radio 结束 */

/* Menu 开始 */
// .el-tooltip__popper.is-dark {
//   background: rgb(255, 255, 255);
//   margin-left: 5px;
//   margin-right: 5px;
//   padding-left: 20px;
//   color: rgb(51, 51, 51);
//   min-width: 200px;
//   border-radius: 0;
//   box-shadow: $box-shadow;
//   cursor: pointer;

//   span {
//     line-height: 34px;
//   }
// }

// .el-tooltip__popper.is-dark:hover {
//   background: rgb(204, 204, 204);
// }

// .el-tooltip__popper .popper__arrow,
// .el-tooltip__popper .popper__arrow::after {
//   display: none;
// }

.el-tooltip__popper.is-dark {
  max-width: 300px !important;
}

.el-menu--popup {
  padding: 0;
}

/* Menu 结束 */

/* 修改手機點擊兩次 */
.el-scrollbar {
  >.el-scrollbar__bar {
    opacity: 1;
  }
}

.el-message__icon {
  @include rtl-sass-prop(margin-right, margin-left, 10px);
}

.el-scrollbar__wrap {
  // background-color: $tertiary-purple;
  @include rtl-sass-prop-dual(margin-right, 0, margin-right, 0 !important);
  @include rtl-sass-prop-dual(margin-left, 0, margin-left, -6px !important);
}

.el-icon-arrow-left {
  @include rtl-sass-prop-sd(transform, rotate(180deg));
}

.el-icon-arrow-right {
  @include rtl-sass-prop-sd(transform, rotate(180deg));
}

.el-carousel__arrow--left {
  @include rtl-sass-prop-sd(right, 16px);
  @include rtl-sass-prop-sd(left, unset);
}

.el-carousel__arrow--right {
  @include rtl-sass-prop-sd(left, 16px);
  @include rtl-sass-prop-sd(right, unset);
}

.el-card {
  color: #666666;

  .el-card__header {
    border: none;
    padding: 8px 20px;
    line-height: 1;

    span {
      color: #333333;
    }
  }
}

.el-row--flex {
  flex-wrap: wrap;
}

.el-notification {
  .el-notification__content {
    a {
      color: $primary-purple;
      text-decoration: underline;
    }
  }
}

@media (max-width: 768px) {
  .el-dialog__wrapper {
    .el-dialog {
      top: 50px;
      transform: none;
    }
  }

  .vUpload {
    grid-template-columns: repeat(auto-fit, minmax(100%, 1fr));
    row-gap: 15px;
  }

  .upload_demo .button_upload {
    position: unset;
  }

  .el-upload__tip {
    text-align: center;
  }
}

/* 日期組件样式开始 */

.el-date-editor.el-input,
.el-date-editor.el-input__inner {
  width: 180px;
}

/* 左侧日历图标 */

.el-input__icon.dataIcon {
  width: 24px;
  height: 24px;
  margin-top: 10px;
  content: url(~@/assets/images/icons-tools-calendar@3x.png);
}

/* 修改日历下拉的样式 */

.el-picker-panel {
  box-shadow: 0 9px 35px 0 rgba(0, 0, 0, 0.13);
  background-color: #ffffff;
  border: unset;

  .popper__arrow {
    display: none;
  }
}

/* 下拉字体颜色 */
.el-picker-panel__icon-btn {
  color: $primary-purple;
}

.el-date-picker__header-label {
  color: $primary-purple;
  font-weight: bold;
}

.el-date-picker__header {
  .el-icon-arrow-left {
    @include rtl-sass-prop-sd(transform, rotate(0deg));
  }

  .el-icon-arrow-right {
    @include rtl-sass-prop-sd(transform, rotate(0deg));
  }
}

.el-picker-panel__content tbody {
  color: rgb(119, 119, 119);
}

.el-date-table th {
  color: $primary-purple;
  font-weight: bold;
  padding: 0;
}

.el-date-table td.current:not(.disabled) span {
  color: #ffffff;
  background: $primary-purple;
}

.el-date-table td.today span {
  color: #ffffff;
  background: $primary-green;
}

.el-date-table td.next-month,
.el-date-table td.prev-month {
  color: #cccfd4;
}

.el-date-table td.available {
  color: $primary-purple;
}

.el-date-table td.disabled div {
  background-color: #e5e6e9;
  color: #666e7e;
}

.el-year-table td.disabled .cell {
  background-color: #e5e6e9;
  color: #666e7e;
}

.el-month-table td.disabled .cell {
  background-color: #e5e6e9;
  color: #666e7e;
}

/* 日期組件样式结束 */

@media (max-width: 550px) {
  .el-input__inner {
    font-size: 16px;
  }

  .el-form-item__error {
    font-size: 12px;
  }
}