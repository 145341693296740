
              @import "@/assets/css/variables/variables.scss";
            
* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  word-break: break-word;
}

*:before,
*:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
code,
form,
fieldset,
legend,
input,
button,
textarea,
p,
blockquote,
th,
td {
  margin: 0;
  padding: 0;
}

body {
  font-size: 14px;
  font-family: 'GTWalsheimPro-Medium';

  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
}

td,
th,
caption {
  font-size: 14px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: normal;
  font-size: 100%;
}

address,
caption,
cite,
code,
dfn,
em,
strong,
th,
var {
  font-style: normal;
  font-weight: normal;
}

a {
  color: #666666;
  text-decoration: none;
  cursor: pointer;
}

p {
  line-height: 1.1;
}

img {
  border: none;
  max-width: 100%;
  vertical-align: middle;
}

ol,
ul,
li {
  list-style: none;
}

input,
textarea,
select,
button {
  font-size: 18px;
  font-family: 'GTWalsheimPro-Medium';
}

table {
  border-collapse: collapse;
}

.clearfix:after {
  content: '.';
  /*加一段内容*/
  display: block;
  /*让生成的元素以块级元素显示，占满剩余空间*/
  height: 0;
  /*避免生成的内容破坏原有布局高度*/
  clear: both;
  /*清除浮动*/
  visibility: hidden;
  /*让生成的内容不可见*/
}

.clearfix {
  zoom: 1;
  /*为IE6，7的兼容性设置*/
}

/*公共类*/

.fl {
  @include rtl-sass-value(float, left, right);
}

.fr {
  @include rtl-sass-value(float, right, left);
}

.al {
  text-align: left;
}

.ac {
  text-align: center;
}

.ar {
  text-align: right;
}

.hide {
  display: none;
}

input {
  border: none;
  outline: none;
}

select {
  border: none;
  outline: none;
}

@media (min-width: 991px) {
  /* width */
  ::-webkit-scrollbar {
    width: 6px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: transparent;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background-color: rgba(144, 147, 153, 0.3);
    transition: background-color 0.3s;
    border-radius: 10px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background-color: rgba(144, 147, 153, 0.3);
    transition: background-color 0.3s;
  }
}
